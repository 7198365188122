// import styles bundle
import ajax from '@fdaciuk/ajax'
import $ from 'jquery';
import 'jquery-validation';
import "../css/style.scss"
import Pristine from 'pristinejs';
import Swiper from 'swiper';
import { Navigation, Pagination, Controller, Autoplay } from 'swiper/modules';
import Checkout from "./modules/Checkout";

const app = {

    getValidationConfig: () => {

        Pristine.addValidator(
            'equals',
            function (val, otherFieldSelector) {
                var other = document.querySelector(otherFieldSelector);
                return other && ((!val && !other.value) || other.value === val);
            },
            'Fields do not match',
            1,
            true
        );
      
        let config = {
            classTo: 'form__input__group',
            errorClass: 'form__input__group--error',
            successClass: 'form__input__group--success',
        };
      
        return config;
      
    },

    checkoutVali: () => {

      var form = document.querySelector('form.checkout');
      var btn = document.querySelector('#place_order');
      
      if (btn) {

        btn.addEventListener('click', (e) => {
          e.preventDefault();
  
          alert('xf');
        });
      }



  },

    playVideo: () => {
      let sections = document.querySelectorAll('.video-wrapper');

      if (sections) {
        sections.forEach(section => { 

          const btns = section.querySelectorAll('.play-video')
          const video = section.querySelector('.video-popup')

          btns.forEach(btn => {
            btn.addEventListener('click', () => {
              event.preventDefault(); 
              document.body.classList.add('body-no-scroll');
              video.classList.add('active')

              const exit = video.querySelector('.close');
              if (exit) {
                exit.addEventListener('click', () => {
                  video.classList.remove("active");
                  document.body.classList.remove('body-no-scroll')
                });
              }

            })
          })

        });
      }

    },

    tabSwitcher: () => {

      let tabsWrapper = document.querySelectorAll('.tabs-wrapper');
  
      if (tabsWrapper) {
        tabsWrapper.forEach(tabWrapper => {
  
          const tabs = tabWrapper.querySelectorAll('[data-tab-target]')
          const tabContents = tabWrapper.querySelectorAll('[data-tab-content]')
  
          tabs.forEach(tab => {
            tab.addEventListener('click', () => {
              const target = document.querySelector(tab.dataset.tabTarget)
              tabContents.forEach(tabContent => {
                tabContent.classList.remove('active')
              })
              tabs.forEach(tab => {
                tab.classList.remove('active')
              })
              tab.classList.add('active')
              target.classList.add('active')
            })
          })
  
         });
      }
  
    },

    productOptions: () => {
    
      let section = document.querySelector('.fpf-radio-images');
    
      if (section) {
    
        const inputs = section.querySelectorAll('input');
    
        if (inputs.length > 0) {
          const firstInput = inputs[0];
          firstInput.click();
          console.log(firstInput);
        }
      }
    
    },

    searchFilters: () => {

      let searchForms = document.querySelectorAll('.header-search-bar');
  
  
      if ( searchForms ) {
  
        [].forEach.call(searchForms, e => {
  
          let searchForm 		= e.querySelector('.header-search-bar__form');
          let searchInput = e.querySelector('.header-search-bar__input');
          let listing 			= e.querySelector('.header-search-bar-response');
  
  
          let timeout;
  
          searchInput.addEventListener('keydown', () => {
  
            clearTimeout(timeout);
  
            // Set timeout - user may choose multiple options - fire after 1s
            timeout = setTimeout(() => {
  
              let searchFormData = new FormData(searchForm);
  
              var search 			= new URLSearchParams(searchFormData );
              var queryString 	= search.toString();
              var base 			= searchForm.getAttribute('data-base');
  
              searchFormData.append('action', searchForm.getAttribute('data-action'));
  
              let request = ajax({
                method: 'POST',
                headers: {
                    'content-type': false
                },
                url: wp.ajax,
                data: searchFormData
              })
              request.then(function(response) {
  
                listing.innerHTML 			= response.results_html;
                app.showSearchResults();
                
              });
  
  
            }, 400);
  
          });
  
        });
  
      }
  
    },

    showSearch: () => {

      let searches = document.querySelectorAll('.site__header__search');
      let back = document.querySelector('.matte-background');
  
      if ( searches ) {
  
        [].forEach.call(searches, search => {
  
          let searchBtn = search.querySelector('.site__header__search__btn');
          let input = search.querySelector('.header-search-bar__input');
          let target = search.querySelector('.header-search-bar');
          let close = search.querySelector('.search-close');

          let closeMobile = search.querySelector('.header-search-bar__form--back');
  
          var back = document.querySelector('.matte-background');
  
            searchBtn.addEventListener('click', () => {
              input.value = ""
              document.body.classList.add('body-no-scroll');
              target.classList.add("active");
              searchBtn.classList.add("hide-me");
              back.classList.add("active");
  
              input.focus();


              function closeHandler() {
                back.classList.remove("active");
                input.value = "";
                document.body.classList.remove('body-no-scroll');
                target.classList.remove("active");
                searchBtn.classList.remove("hide-me");
              }
              
              if (closeMobile) {
                closeMobile.addEventListener('click', closeHandler);
              }
              
              if (close) {
                close.addEventListener('click', closeHandler);
              }
  
              document.addEventListener('click', event => {
                if (!search.contains(event.target)) {
                  back.classList.remove("active");
                  input.value = ""
                  document.body.classList.remove('body-no-scroll')
                  target.classList.remove("active");
                  searchBtn.classList.remove("hide-me");
  
                }
              });
  
            });
  
  
  
        });
  
  
      }
  
  
    },
    
    videoPopupHandler: () => {

		const videoBox = document.querySelector('.video__box');
	
		videoBox.addEventListener('click', () => {
			const videoPopup = document.getElementById('videoPopup');
			videoPopup.style.display = 'block';
		});
	
		const closeBtn = document.querySelector('.video-popup .close');
	
		closeBtn.addEventListener('click', () => {
			const videoPopup = document.getElementById('videoPopup');
			const iframe = videoPopup.querySelector('iframe');
			const videoSrc = iframe.src;
			iframe.src = ''; 
			iframe.src = videoSrc; 
			videoPopup.style.display = 'none';
		});
	
		window.addEventListener('click', (event) => {
			const videoPopup = document.getElementById('videoPopup');
			if (event.target === videoPopup) {
				videoPopup.style.display = 'none';
				const iframe = videoPopup.querySelector('iframe');
				iframe.src = iframe.src; 
			}
		});
	},

    popup: () => {

		const btn = document.querySelector('.product .info-box');

    if (btn) {
      const popup = document.querySelector('.popup');

	
      btn.addEventListener('click', () => {
              popup.classList.add("active");
      });
    
      const closeBtn = document.querySelector('.popup-exit');
    
      closeBtn.addEventListener('click', () => {
              popup.classList.remove("active");
      });
    }

	

	},

    forms: () => {

        // Get validation config
        let config 				= app.getValidationConfig();
    
        // Get forms to validate
        let contactForms			= document.querySelectorAll('.contact__form');
    
            // Newsletter Form Validation
            if ( contactForms	) {
            [].forEach.call(contactForms, contactForm => {
                    let contactFormValidator = new Pristine(contactForm, config, true);
                    contactForm.addEventListener("submit", function (e) {
                        if ( !contactFormValidator.validate() ) {
                            e.preventDefault();
                        }
                    });
    
                });
    
            }
    
    },

    contactForm: () => {

        let config 				= app.getValidationConfig();
        let forms 		    = document.querySelectorAll('.contact__form');
    
        if ( forms ) {
    
          [].forEach.call(forms, form => {
    
            let submitBtn   = form.querySelector('.btn');
            let msg         = form.querySelector('.contact-msg--success');
            let formValidator = new Pristine(form, config, true);
    
              submitBtn.addEventListener('click', () => {
                if ( formValidator.validate()  ) {
                event.preventDefault();
                var formData = new FormData(form);
    
                formData.append('action', form.getAttribute('data-action'));
    
                let request = ajax({
                  method: 'POST',
                  headers: {
                      'content-type': false
                  },
                  url: wp.ajax,
                  data: formData
                })
                request.then(function(response) {
                    msg.classList.add('active');
                    form.reset();
                    formValidator.reset();
                    let inputFields = form.querySelectorAll('.input');
                    [].forEach.call(inputFields, field => {
                        field.value = '';
                    });
                });
               }
    
              }, false);
    
            });
          }
      },

    showAllBtn: () => {

        let teamList = document.querySelector('.classes__filters');
    
        if (teamList) {
    
            let teamListItems = teamList.querySelectorAll('.filter__list__item input');
            let showAll = teamList.querySelector('.show-all');
    
            showAll.addEventListener('click', () => {
                showAll.classList.add('active');
                [].forEach.call(teamListItems, btn => {
                     btn.checked = false;
                });
                app.catsAjax();
            });
    
    
            }
    },

    mobileCategories: () => {

      const categoryTitles = document.querySelectorAll('.categories-title');

      categoryTitles.forEach(title => {
        title.addEventListener('click', function() {
          const parent = this.closest('.left');
          if (parent) {
            parent.classList.add('active');
          }
        });
      });

      const closeButtons = document.querySelectorAll('.left .close');

      closeButtons.forEach(button => {
        button.addEventListener('click', function() {
          const parent = this.closest('.left');
          if (parent) {
            parent.classList.remove('active');
          }
        });
      });

  },

	catsAjax: () => {

        let filtersForm 		= document.querySelector('.classes__filters');
    
        if ( filtersForm ) {

            let listing 			= document.querySelector('.classes__products');
            let inputs 				= document.querySelectorAll('.classes__filters .filter-item');
 

    
            let timeout;
    
            [].forEach.call(inputs, e => {
    
                e.addEventListener('click', () => {
    
                    clearTimeout(timeout);
    
                    // Set timeout - user may choose multiple options - fire after 1s
                    timeout = setTimeout(() => {
                        let filtersFormData = new FormData(filtersForm);
           
                        var search 			= new URLSearchParams(filtersFormData);
               
                        var base 			= filtersForm.getAttribute('data-base');
                        
    
    
                        var search 			= new URLSearchParams(filtersFormData);
                        var queryString 	= search.toString();
                        if ( window.history ) {
                            window.history.pushState("", "", base + '?' + queryString);
                        }
    
                        filtersFormData.append('action', filtersForm.getAttribute('data-action'));
    
                        let request = ajax({
                                                method: 'POST',
                                                headers: {
                                                        'content-type': false
                                                },
                                                url: wp.ajax,
                                                data: filtersFormData
                                            })
                                            request.then(function(response) {
                                                listing.innerHTML 	= response.classes_html;
                                            });
    
                    }, 100);
    
                });
    
            });
         }
    },

    mobileMenu: () => {

        let buttons = document.querySelectorAll('.header-mobile__cont .header__right__menu__item--sub .button-cat');
     
         if (buttons) {
           console.log(buttons);
           buttons.forEach(button => {
      
               button.addEventListener('click', (e) => {
                 e.preventDefault();
                 var panel = button.nextElementSibling;
                 if (panel.classList.contains("list-visible")) {
                   panel.classList.remove("list-visible");
                 } else {
                   panel.classList.add("list-visible");
                 }
               })
           });
     
     
     
         }
     
    },

    showMobileMenu: () => {
        let mobileMenu = document.querySelector('.header-mobile-cont');
        let hamburger = document.querySelector('#opener');
        
        if (hamburger) {
         
              hamburger.addEventListener('click', () => {
                mobileMenu.classList.toggle('active');
                hamburger.classList.toggle('active');
                document.body.classList.toggle('body-no-scroll');
              });
    
            }
    },


    howSwiper: () => {
      let swipers = document.querySelectorAll('.how-swiper');
  
      if (swipers) {
          [].forEach.call(swipers, swiper => {
              var mySwiper = new Swiper(swiper, {
                  modules: [Navigation, Pagination, Autoplay],
                  effect: 'slide',
                  loop: true,
                  speed: 2000,
                  slidesPerView: 1,
                  spaceBetween: 30,
                  grabCursor: true,
                  watchOverflow: true,
                  watchSlidesProgress: true,
                  watchSlidesVisibility: true,
                  autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                  },
                  pagination: {
                      el: ".swiper-pagination",
                      type: "progressbar",
                  },
                  on: {
                      init: function() {
                          updateCounter(this.realIndex); // Use realIndex to get the original index
                      },
                      slideChange: function() {
                          updateCounter(this.realIndex); // Use realIndex to get the original index
                      }
                  }
              });
  
              function updateCounter(activeIndex) {
                  if (typeof activeIndex !== 'undefined') {
                      let counters = document.querySelectorAll('.counter__number');
                      counters.forEach((counter, index) => {
                          if (index === activeIndex) {
                              counter.classList.add('active');
                          } else {
                              counter.classList.remove('active');
                          }
                      });
                  }
              }
  
              updateCounter(0);
          });
      }
},



reviewsSwiper: () => {

  Swiper.use([Navigation, Pagination, Controller]);

    var authorSwiper = new Swiper('.reviews-authors', {
      slidesPerView: 1,
      loop: true,
      centeredSlides: true,

      navigation: {
          nextEl: '.rev-next',
          prevEl: '.rev-prev',
      },
  });

  var contentSwiper = new Swiper('.reviews-content', {
      slidesPerView: 1,
      loop: true,
      centeredSlides: true,


      navigation: {
          nextEl: '.rev-next',
          prevEl: '.rev-prev',
      },
  });

  authorSwiper.controller.control = contentSwiper;
  contentSwiper.controller.control = authorSwiper;

},


  projectsSwiper: () => {

		let pswipers = document.querySelectorAll('.projects-swiper');
		

		if ( pswipers) {
			
			[].forEach.call(pswipers, swiper => {

				var pSwiper = new Swiper(swiper, {
          modules: [Navigation, Pagination],

					effect: 'slide',
					speed: 1000,
					slidesPerView: 3,
					spaceBetween: 30,
					grabCursor: true,
					watchOverflow: true,
					watchSlidesProgress: true,
					watchSlidesVisibility: true,
          breakpoints: {
            // when window width is >= 320px
            220: {
              slidesPerView: 1,
            },
            440: {
              slidesPerView: 2,
            },
            740: {
              slidesPerView: 3,
            }
          },
          // Navigation arrows
          navigation: {
            nextEl: swiper.querySelector('.swiper-button-next'),
            prevEl: swiper.querySelector('.swiper-button-prev'),
          },

		
				  });

			});
		}

	},

    categories: () => {

        let cats = document.querySelectorAll('.shop-categories--first');
     
         if (cats) {

           cats.forEach(cat => {

               let button = cat.querySelector('.opener');

               button.addEventListener('click', (e) => {

                 e.preventDefault();

                 var menu = cat.querySelector('.submenu');

                 if (cat.classList.contains("list-visible")) {
                   cat.classList.remove("list-visible");
                 } else {
                   cat.classList.add("list-visible");
                 }

               })

           });
     
     
     
         }
     
    },

    accords: () => {

      const accordions = document.querySelectorAll('.faq-container');

      if (accordions.length > 0) {
        accordions.forEach(accordion => {
          const accTitles = accordion.getElementsByClassName("faq-item__title");
    
          if (accTitles.length > 0) {
            Array.from(accTitles).forEach(title => {
              title.addEventListener("click", function() {
                // Close all other accordions within the same container
                Array.from(accTitles).forEach(otherTitle => {
                  if (otherTitle !== this && otherTitle.parentElement.classList.contains("active")) {
                    otherTitle.parentElement.classList.remove("active");
                  }
                });
    
                // Toggle the clicked accordion
                this.parentElement.classList.toggle("active");
              });
            });
          }
        });
      }
      
    },

    showSearchResults: () => {

      let searches 		= document.querySelectorAll('.header-search-bar.active');
  
      if ( searches )  {
  
        [].forEach.call(searches, e => {
  
          let searchBtn	= e.querySelector('.header-search-bar__icon');
          let btn	= e.querySelector('.search-listing__more-btn');
  
          if ( btn ) {
            btn.addEventListener('click', () => {
              searchBtn.click();
            });
          }				
  
        });
  
      }
  
    },

    init: () => {
      app.checkoutVali();
      Checkout.init();
      app.productOptions();
      app.playVideo();
      app.showSearch();
      app.searchFilters();
      app.projectsSwiper();
      app.showSearchResults();
      app.reviewsSwiper();
      app.accords();
      app.tabSwitcher();
      app.howSwiper();
      app.categories();
      app.popup();

      app.showMobileMenu();
      app.mobileCategories();
	},

}



document.addEventListener("DOMContentLoaded", () => {
	app.init();

});
