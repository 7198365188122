class Checkout {

	constructor( element ) {


		let customerType 				= element.querySelectorAll('.customer-select__radio input');
		let selectedCustomerType 		= element.querySelector('.customer-select__radio input:checked');
		let companyFields 				= element.querySelectorAll('.company-field');


		if ( customerType ) {

			// Preselect option if data is stored in session
			if ( selectedCustomerType.value == 'company' ) {
				[].forEach.call(companyFields, field => {
					field.style.display = "block";
					field.classList.add("validate-required");
				});
			}

			// On change - show/hide fields
			[].forEach.call(customerType, type => {

				type.addEventListener('change', (e) => {

					if ( type.value == 'company' ) {
						[].forEach.call(companyFields, field => {
							field.style.display = "block";
							field.classList.add("validate-required");
						});
					} else {
						[].forEach.call(companyFields, field => {
							field.style.display = "none";
							field.classList.remove("validate-required");
						});
					}

				});

			});

		}

	}

	static init() {
		document.querySelectorAll(".woocommerce-checkout").forEach(element => {
			new Checkout(element)
		});
	}

}

export default Checkout